import { SiteNavLinkTabs } from "./SiteNavLinkTabs";
import UserProfileDropdown from "@components/UserProfile/UserProfileDropdown";
import config from "@config/config";
import { Navbar, NavbarComponentProps } from "flowbite-react";
import { NavLink } from "react-router-dom";
import { LIGHT_LOGO, DARK_LOGO, SYMBOL_LOGO } from "@config/constants";

export type SiteHeaderProps = NavbarComponentProps;

export function SiteHeader(props: SiteHeaderProps) {
  const classicVersionUrl = import.meta.env.VITE_CLASSIC_VERSION_URL;

  return (
    <Navbar
      className="sticky top-0 z-50 px-2 py-2 sm:px-4 dark:bg-black"
      fluid
      {...props}
    >
      <Navbar.Brand
        as={NavLink}
        to={config.baseUrl}
        className="md:ml-0 md:mr-8"
      >
        <img
          className="md:hidden dark:hidden"
          src={SYMBOL_LOGO}
          alt="CarbonArc symbol"
          width="32"
          height="32"
        />
        <img
          className="hidden md:block dark:hidden"
          src={LIGHT_LOGO}
          alt="CarbonArc full logo"
          width="120"
          height="24"
        />
        <img
          className="hidden dark:block"
          src={DARK_LOGO}
          alt="CarbonArc white logo"
          width="120"
          height="24"
        />
        <div className="flex flex-wrap gap-2">
          <span className="bg-green-100 text-green-800 text-xs font-medium me-2 px-2.5 py-0.5 rounded dark:bg-green-900 dark:text-green-300">BETA</span>
        </div>

      </Navbar.Brand>
      <div className="hidden md:flex flex-grow items-center gap-4">
        <SiteNavLinkTabs />
      </div>
      <div className="mr-6">
        <a className="inline-flex items-center ml-2 text-sm font-medium text-blue-600 md:ml-2 dark:text-blue-500 hover:underline" href={classicVersionUrl}>
          Switch to Classic Version
        </a>
      </div>
      <div className="flex items-center gap-4">
        <div className="flex gap-1 items-center">
          <UserProfileDropdown />
        </div>
      </div>
    </Navbar>
  );
}
import { useCallback } from 'react'
import { fetchUserFeatureAccess } from '@services/auth'
import { useQuery } from '@tanstack/react-query'

export function useFeatureAccess() {
  const {
    data = [],
    isLoading,
    isError,
    isFetched,
  } = useQuery<string[]>({
    queryKey: ['user-feature-access'],
    queryFn: () => fetchUserFeatureAccess(),
  })

  const canAccessFeature = useCallback(
    (featureAccess: string) => {
      return data.includes(featureAccess)
    },
    [data],
  )

  return { canAccessFeature, isLoading, isError, isFetched }
}

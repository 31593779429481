import { useState, useCallback } from 'react'

const useDropdown = (initialState = false) => {
  const [isOpen, setIsOpen] = useState(initialState)
  const toggleDropdown = useCallback(() => setIsOpen((prevState) => !prevState), [])

  return { isOpen, toggleDropdown, setIsOpen }
}

export default useDropdown

import OktaLogin from "@components/Okta/Login";
import { EmptyLayout } from "@layouts/EmptyLayout";
import { useOktaAuth } from "@okta/okta-react";
import { Navigate } from "react-router-dom";

const Login = () => {
  const { authState } = useOktaAuth();
  if (!authState) {
    return <EmptyLayout />;
  }
  if (authState.isAuthenticated) return <Navigate to="/home" />;
  return <OktaLogin />;
};

export default Login;

import { Outlet } from "react-router-dom";

export const EmptyLayout = () => {
  return (
    <div className="min-h-full flex bg-gray-50 dark:bg-black">
      <main className="flex-1">
        <Outlet />
      </main>
    </div>
  );
};

const storageKey = "carc-last-visited";

// set the last visited page from storage
export const setLastVisited = (path: string) => {
  localStorage.setItem(storageKey, path);
};

// get the last visited page from storage
export const getLastVisited = () => {
  return localStorage.getItem(storageKey);
};

// remove the last visited page from storage
export const removeLastVisited = () => {
  return localStorage.removeItem(storageKey);
};

import { Toaster as ReactHotToaster, ToasterProps } from "react-hot-toast";

const Toaster = (props: ToasterProps = {}) => {
  return (
    <ReactHotToaster
      position="top-right"
      toastOptions={{
        success: {
          iconTheme: {
            primary: "#4DFF00",
            secondary: "#000",
          },
        },
        style: {
          padding: "16px",
          boxShadow: "0px 0px 32px 0px #00000052",
        },
      }}
      {...props}
    />
  );
};

export default Toaster;

import React from 'react'

export enum Pages {
  charts = 'charts',
  insights = 'insights',
  entities = 'entities',
  dashboards = 'dashboards',
  ontology = 'ontology',
  api = 'api',
  data = 'data-libraries',
  workspace = 'workspace',
  workbench = 'workbench',
  docs = 'docs',
}

export type NavbarDropdown = {
  key: string
  type: 'dropdown'
  title: string
  items: NavbarLink[]
  fAccessKey?: string
  canAccess?: boolean
}

export type NavbarLink = {
  key: Pages
  type: 'link'
  title: string
  info?: string
  description?: string
  route: string
  Component?: React.FC
  icon?: React.ElementType
  isExternalSite?: boolean
  fAccessKey?: string
  canAccess?: boolean
}

export type NavbarItem = NavbarLink | NavbarDropdown

export type NavbarRouteLink = NavbarLink & { Component: React.FC }

import { useAuth } from "@components/Okta";
import { CiUser } from "react-icons/ci";
import { twMerge } from "tailwind-merge";

interface TUserAvatarProps {
  size?: "small" | "medium";
}
const UserAvatarSizes = {
  small: "w-8 h-8 ",
  medium: "w-14 h-14 text-2xl",
};
const UserAvatar = ({ size = "medium" }: TUserAvatarProps) => {
  const { user } = useAuth();
  const firstName = user?.given_name;
  const lastName = user?.family_name;
  
  const initials = firstName && lastName 
    ? `${firstName[0].toUpperCase()}${lastName[0].toUpperCase()}` 
    : firstName 
      ? firstName[0].toUpperCase() 
      : null;

  return (
    <div
      className={twMerge(
        "ml-2 bg-black text-white rounded-full flex items-center justify-center dark:bg-ca-600",
        UserAvatarSizes[size]
      )}
    >
      {initials ? initials : <CiUser />}
    </div>
  );
};

export default UserAvatar;

import { pageData } from "@config/pages";
import { BaseLayout } from "@layouts/BaseLayout";
import { useOktaAuth } from "@okta/okta-react";
import Root from "@routes/root";
import { setLastVisited } from "@stores/lastVisited";
import { useEffect } from "react";
import { Navigate, Route, Routes, useLocation } from "react-router-dom";

export const ProtectedRouter = () => {
  const { authState } = useOktaAuth();
  const location = useLocation();

  useEffect(() => {
    // if user got logged out, record the last visited page
    if (authState && !authState.isAuthenticated) {
      setLastVisited(location.pathname);
    }
    // removed location from dependencies
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authState]);

  return (
    <Routes>
      <Route element={<Root />}>
        <Route element={<BaseLayout />}>
          <Route path="/" element={<Navigate to={pageData.home.path} />} />
          {Object.values(pageData).map(({ path, component: Component }) => {
            return <Route key={path} path={path} element={<Component />} />;
          })}
          <Route path="*" element={<>Not found</>} />
        </Route>
      </Route>
    </Routes>
  );
};

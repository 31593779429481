import { SiteHeader } from "@components/Okta/Layout/SiteHeader";
import { EmptyLayout } from "@layouts/EmptyLayout";
import { useOktaAuth } from "@okta/okta-react";
import { Spinner } from "flowbite-react";
import { Suspense } from "react";
import { Outlet } from "react-router-dom";

export const BaseLayout = () => {
  const { authState } = useOktaAuth();

  if (!authState) {
    return <EmptyLayout />;
  }

  // This Suspense component will be triggered when lazy loaded
  // route components are being rendered
  return (
    <div className="h-full flex flex-col min-h-screen max-h-screen">
      <SiteHeader />
      <Suspense fallback={<Spinner className="m-5">...</Spinner>}>
        <main className="flex-1 dark:bg-black flow-x-auto relative w-full flex flex-col md:min-h-[calc(100vh-88px)]">
          <Outlet />
        </main>
      </Suspense>
    </div>
  );
};

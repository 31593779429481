import { AuthRoute } from "@components/Okta";
import { LoginCallback } from "@okta/okta-react";
import Login from "@pages/login";
import { ProtectedRouter } from "@routes/protected";
import { createBrowserRouter, createRoutesFromElements, Route } from "react-router-dom";

export const router = createBrowserRouter(
  createRoutesFromElements(
    <Route element={<AuthRoute />}>
      <Route path="/login" element={<Login />} />
      <Route path={`/login/callback`} element={<LoginCallback />} />
      <Route path="/*" element={<ProtectedRouter />} />
    </Route>
  )
);

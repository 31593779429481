import { ApiError } from "@services/api";
import { MutationCache, QueryClient } from "@tanstack/react-query";
import toast from "react-hot-toast";

const MINUTE = 1000 * 60;

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: 5 * MINUTE, // 5 mins
      //data will be "garbage collected" to avoid the cache from growing after 10 mins
      gcTime: 10 * MINUTE,
    },
  },
  mutationCache: new MutationCache({
    onError: (error: Error) => {
      const apiError = error as ApiError;
      toast.error(apiError.detail);
    },
  }),
});

import { NavLink, useLocation } from "react-router-dom";
import { FiSearch } from "react-icons/fi";

const SearchButton = () => {
  const { pathname } = useLocation();

  return (
    <NavLink
      to="/search"
      className={`p-1 mt-1 rounded-full hover:text-bold focus:outline-none focus-visible:ring-2 focus-visible:ring-white/75 ${
        pathname === "/search"
          ? "text-black dark:text-white"
          : pathname.endsWith("/home")
            ? "text-black dark:text-white"
            : "text-gray-400 dark:text-gray-400"
      }`}
      aria-label="Search"
    >
      <FiSearch className="h-5 w-5 cursor-pointer" />
    </NavLink>
  );
};

export default SearchButton;

const loginCallback = "/login/callback";

const config = Object.freeze({
  okta: {
    clientId: import.meta.env.VITE_OKTA_CLIENT_ID ?? "",
    redirectUri: `${window.location.origin}${loginCallback}`,
    issuer: import.meta.env.VITE_OKTA_ISSUER ?? "",
    pkce: true,
    scopes: ["openid", "profile", "email", "groups"],
    postLogoutRedirectUri: `${window.location.origin}/login`,
  },
  baseUrl: "/home",
  apiUrl: import.meta.env.VITE_API_BASE_URL ?? "",
  apiSampleCodeBaseUrl: import.meta.env.VITE_API_SAMPLE_CODE_BASE_URL ?? "https://api.carbonarc.co/v1/insights/",
  superset: {
    url: import.meta.env.VITE_SUPERSET_URL ?? "https://insights.carbonarc.co",
  },
  featureFlags: {
    hiddenNavbarItems:
      import.meta.env.VITE_FF_HIDDEN_NAVBAR_ITEMS?.split(",") ?? [],
  },
  carcAuth: {
    url: import.meta.env.VITE_CARC_AUTH_SERVICE_URL ?? "",
    product_id: import.meta.env.VITE_CARC_AUTH_PRODUCT_ID ?? "",
  },
});

export default config;

import { AsyncFn } from "../../hooks/useAsync";
import OktaAuth, { OktaAuthOptions, UserClaims } from "@okta/okta-auth-js";
import { createContext, useContext } from "react";

export type AuthContextType = {
  logo?: React.ReactNode;
  header?: React.ReactNode;
  footer?: React.ReactNode;
  signOut: AsyncFn<void>;
  okta: OktaAuth | null;
  oktaOptions: OktaAuthOptions | null;
  user: UserClaims | null;
};

const nonImplemented = async () => {
  throw new Error("Not implemented");
};

nonImplemented.reset = nonImplemented;

const authContext = createContext<AuthContextType>({
  signOut: (() => {
    const signOutFn = async () => {
      localStorage.clear()      
      await nonImplemented()
    }

    signOutFn.reset = () => {}
    return signOutFn
  })(),
  oktaOptions: null,
  okta: null,
  user: null,
});

export const AuthContextProvider = authContext.Provider;
export function useAuth() {
  return useContext(authContext);
}

import { useAuth } from "./AuthContext";
import { toRelativeUrl } from "@okta/okta-auth-js";
import { Security } from "@okta/okta-react";
import { Suspense } from "react";
import { Outlet, useNavigate } from "react-router-dom";

export function AuthRoute() {
  const navigate = useNavigate();
  const { okta } = useAuth();
  const onAuthRequired = () => {
    navigate("/login");
  };
  const restoreOriginalUri = async (_: unknown, originalUri: string) => {
    const url = toRelativeUrl(originalUri, window.location.origin);
    window.location.href = url;
    navigate(url, { replace: true });
  };
  if (!okta) return <>Not found</>;

  return (
    <Suspense fallback={<>...</>}>
      <Security
        oktaAuth={okta}
        onAuthRequired={onAuthRequired}
        restoreOriginalUri={restoreOriginalUri}
      >
        <Outlet />
      </Security>
    </Suspense>
  );
}

import config from "@config/config";
import { apiFetch } from "@services/api";

const AUTH_BASE_URL = config.carcAuth.url;

export async function fetchUserProfile() {
  const re = await apiFetch(`${AUTH_BASE_URL}/users/me/profile`);
  return re;
}
export async function fetchUserFeatureAccess() {
  return apiFetch(
    `${AUTH_BASE_URL}/users/me/products/${config.carcAuth.product_id}/feature-access`
  );
}

export type TMyRole = {
  role: string;
  product_id: string;
  user_id: string;
};

export async function fetchUserRole() {
  return apiFetch<TMyRole>(
    `${AUTH_BASE_URL}/users/me/product/${config.carcAuth.product_id}/role`
  );
}

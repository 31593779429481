import { NavbarItem, NavbarRouteLink, Pages } from "@/types/navbar";
import { FiBook } from "react-icons/fi";
import { MdOutlineInsights, MdOutlineDataset } from "react-icons/md";
import { PiGraphBold } from "react-icons/pi";
import { RiDashboardLine } from "react-icons/ri";
import { TbBook } from "react-icons/tb";

export const SiteNavbarItems: NavbarItem[] = [
  {
    key: Pages.insights,
    type: "dropdown",
    title: "Explore",
    items: [
      {
        key: Pages.insights,
        type: "link",
        title: "Build",
        route: "/insights",
        description: "Explore Entities, Insights, and Events",
        icon: MdOutlineInsights,
      },
      {
        key: Pages.data,
        type: "link",
        title: "Data",
        route: "/data-library/data-feeds",
        description: "View the Platform's data assets",
        icon: MdOutlineDataset,
        fAccessKey: "caapi_data-sources",
      },
      {
        key: Pages.dashboards,
        type: "link",
        title: "Dashboards",
        description: "Unlock industry focused Dashboards",
        route: "/dashboards/",
        icon: RiDashboardLine,
      },
      {
        key: Pages.ontology,
        type: "link",
        title: "Ontology",
        route: "/ontology",
        description: "Structured entity relationships and hierarchies",
        icon: PiGraphBold,
      },
    ],
  },
  {
    key: Pages.workspace,
    type: "link",
    title: "Workspace",
    route: "/workspaces",
    icon: FiBook,
  },
  {
    key: Pages.api,
    type: "link",
    title: "Developers",
    route: "/api",
    description: "Explore live APIs",
    icon: TbBook,
    fAccessKey: "caapi_apis",
  },
  {
    key: Pages.docs,
    type: "link",
    title: "Docs",
    route: "https://docs.carbonarc.co/api",
    description: "Learn about Carbon Arc platform",
    isExternalSite: true,
    canAccess: true,
    icon: FiBook,
    fAccessKey: "caapi_docs",
  },
];

export const SiteNavbarRouteLinks: NavbarRouteLink[] = SiteNavbarItems.reduce(
  (acc, item) => {
    if (item.type === "link" && !item.isExternalSite) {
      acc.push(item as NavbarRouteLink);
    }

    if (item.type === "dropdown") {
      const filteredItems = item.items.filter(
        (subItem) => subItem.type === "link" && !subItem.isExternalSite,
      );
      acc.push(...(filteredItems as NavbarRouteLink[]));
    }

    return acc;
  },
  [] as NavbarRouteLink[], // Initialize the accumulator as an empty array of NavbarRouteLink
);

export const getNavbarItem = (page: Pages): NavbarItem | undefined => {
  const findNavbarItem = (items: NavbarItem[]): NavbarItem | undefined => {
    for (const item of items) {
      if (item.key === page) return item;
      if (item.type === "dropdown" && item.items?.length) {
        const found = findNavbarItem(item.items);
        if (found) return found;
      }
    }
    return undefined;
  };

  return findNavbarItem(SiteNavbarItems);
};

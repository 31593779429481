import { AuthRequired, useOktaAuth } from "@components/Okta";
import { EmptyLayout } from "@layouts/EmptyLayout";
import { Outlet } from "react-router-dom";

export default function Root() {
  const { authState } = useOktaAuth();
  // If authState is not initialized yet, show the EmptyLayout
  if (!authState) return <EmptyLayout />;
  return (
    <AuthRequired>
      <Outlet />
    </AuthRequired>
  );
}

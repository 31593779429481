import { ApiAuth } from "@ApiAuth";
import { useOktaAuth } from "@okta/okta-react";
import { Navigate } from "react-router-dom";

export type AuthRequiredProps = {
  children: React.ReactNode | React.ReactNode[];
};
export function AuthRequired({ children }: AuthRequiredProps) {
  const { authState } = useOktaAuth();

  if (!authState?.isAuthenticated) {
    return <Navigate to="/login" />;
  }

  return <ApiAuth>{children}</ApiAuth>;
}

import { AuthProvider } from "@components/Okta";
import Toaster from "@components/base/Toaster/Toaster";
import { queryClient } from "@config/react-query";
import customTheme from "@customTheme";
import ErrorBoundary from "@pages/ErrorBoundary";
import { router } from "@routes/router";
import { QueryClientProvider } from "@tanstack/react-query";
import { Flowbite } from "flowbite-react";
import { RouterProvider } from "react-router-dom";
import "utils/i18n";

export default function App() {
  return (
    <Flowbite theme={{ theme: customTheme, mode: "auto" }}>
      <ErrorBoundary>
        <QueryClientProvider client={queryClient}>
          <AuthProvider>
            <RouterProvider router={router} />
          </AuthProvider>
        </QueryClientProvider>
      </ErrorBoundary>
      <Toaster />
    </Flowbite>
  );
}

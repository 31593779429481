import React from "react";
import { useAsync } from "../../hooks/useAsync";
import { AuthContextProvider } from "./AuthContext";
import config from "@/config/config";
import OktaAuth, { UserClaims } from "@okta/okta-auth-js";
import { useCallback, useEffect, useState } from "react";

export const oktaAuth = new OktaAuth(config.okta);
export type AuthProviderProps = {
  logo?: React.ReactNode;
  header?: React.ReactNode;
  footer?: React.ReactNode;
  children: React.ReactNode;
};
export function AuthProvider({
  logo,
  header,
  footer,
  children,
}: AuthProviderProps) {
  const [user, setUser] = useState<UserClaims | null>(null);
  const signOut = useAsync(
    useCallback(async () => {
      const response = await oktaAuth.signOut();
      if (!response) {
        throw Error("Not able to sign out");
      }
    }, [])
  );

  useEffect(() => {
    oktaAuth.isAuthenticated().then((isAuthenticated: boolean) => {
      if (isAuthenticated) {
        oktaAuth.getUser().then((userInfo) => {
          setUser({ ...userInfo, role: "client_user" });
        });
      }
    });
  }, []);

  return (
    <AuthContextProvider
      value={{
        logo,
        header,
        footer,
        signOut: signOut,
        okta: oktaAuth,
        oktaOptions: config.okta,
        user: user,
      }}
    >
      {children}
    </AuthContextProvider>
  );
}

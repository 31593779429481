import { useCallback, useState } from 'react';

export type AsyncFn<TData = undefined, TArgs extends Array<unknown> = []> = {
  (...args: TArgs): Promise<void>;
  reset: () => void;
  isLoading?: boolean;
  error?: Error;
  data?: TData;
};

export function useAsync<TData = undefined, TArgs extends Array<unknown> = []>(fn: (...args: TArgs) => Promise<TData>) {
  const [isLoading, setLoading] = useState(false);
  const [error, setError] = useState<Error | undefined>();
  const [data, setData] = useState<TData | undefined>();

  const callFn = <AsyncFn<TData, TArgs>>useCallback(
    async (...args: TArgs) => {
      try {
        setError(undefined);
        setLoading(true);
        setData(await fn(...args));
      } catch (error) {
        console.error(error);
        setError(error as Error);
      } finally {
        setLoading(false);
      }
    },
    [fn],
  );

  callFn.reset = useCallback(() => {
    setLoading(false);
    setError(undefined);
    setData(undefined);
  }, []);

  callFn.isLoading = isLoading;
  callFn.error = error;
  callFn.data = data;

  return callFn;
}